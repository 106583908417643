import parse from 'html-react-parser'
import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'

import CurrentTravels from '../components/CurrentTravels'
import { GatsbyImage } from 'gatsby-plugin-image'
import Seo from 'gatsby-plugin-wpgraphql-seo'

export default function Page({ data: { page } }) {
  return (
    <Layout>
      <Seo post={page} />

      <div id='main' className='alt'>
        <section id='one'>
          <div className='inner'>
            <header className='major'>
              <h1>{page.title}</h1>
            </header>
            {// check for both featured_media and featured_media.localFile as both can be null
              page.featuredImage ? (
                <span className='image main'>
                  <GatsbyImage
                    alt={page.featuredImage.node.altText}
                    image={page.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                    style={{ maxHeight: '25rem' }}
                  />
                </span>
              ) : null}

            {// current page is offers page which includes current travels
              page.slug === 'angebot' && (
                <CurrentTravels />
              )}

            <main>{parse(page.content)}</main>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    page: wpPage(id: {eq: $id}) {
      slug
      content
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, transformOptions: {fit: COVER})
            }
          }
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      title
    }
  }
`
